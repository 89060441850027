import { user } from "@/api/request";
import { Message } from "element-ui";
const state = () => ({
  userInfo: null,
  isLoginStatus: false,
  infoChange: 0,
});

// getters
const getters = {};

// actions
const actions = {
  //这里使用大了es6中的结构方法，
  //原本actions中的函数接收到的参数是一个上下文对象context
  async userLogin({ state, commit }, data = {}) {
    const res = await user.login(data);
    // console.log("登录过程返回数据", res);
    const user_info = res.data.data;
    commit("set_userInfo", {
      id: user_info.id,
      username: user_info.username,
      email: user_info.email,
      avatar: user_info.avatar,
      interest_tag: user_info.interest,
      no_interest_tag: user_info.no_interest,
      signature: user_info.signature,
      password: user_info.password,
    });
    commit("set_login_status", true);
    commit("set_info_change", 0);
    // return [null, user_info];
    return res;
  },
  async userRegister({ state, commit }, data = {}) {
    const res = await user.register(data);
    if (res.data.code == 200) {
      Message({
        message: "注册成功，请进行登录",
        type: "success",
      });
    } else {
      Message({
        message: "注册失败",
        type: "warning",
      });
    }
    // const user_info = res.data.data;
    // commit("set_userInfo", {
    //   id: user_info.id,
    //   username: user_info.username,
    //   email: user_info.email,
    // });
    // commit("set_login_status", true);
    return res;
  },
  test({ state }, data = {}) {
    console.log("action中的test函数被成功调用，返回结果", state);
    user
      .register(data)
      .then((res) => {
        console.log("注册函数返回结果", res);
        // const user_info = res.data.data;
        return [null, user_info];
      })
      .catch((err) => {
        console.log(err);
        return [err, null];
      });
    console.log("接收到的数据", data);
    // return state;
  },
};

// mutations
const mutations = {
  set_userInfo(state, data) {
    sessionStorage.setItem("userInfo", JSON.stringify(data));
    state.userInfo = data;
  },
  set_login_status(state, data) {
    state.isLoginStatus = data;
  },
  set_info_change(state, data) {
    state.infoChange = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
