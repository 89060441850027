<template>
  <div>
    <!-- 头部导航 -->
    <nav-bar></nav-bar>
    <!-- 内容 -->
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Footer from "../../components/sample/Footer.vue";
export default {
  name: "Layout",
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style></style>
