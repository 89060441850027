import base from "./base";

import request from "./index";

export const article = {
  list(params) {
    return request({
      url: base.article.getlist,
      method: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.article.create,
      method: "post",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  detail(data) {
    console.log(base);
    return request({
      url: base.article.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.article.update + data.id,
      method: "put",
      data,
    });
  },
  delete(data) {
    return request({
      url: base.article.delete + data.id,
      method: "delete",
      data,
    });
  },
  // 主要用于文章创建的时候，在选择一个topic之后，选择文章归属的热搜
  get_category_content(params) {
    return request({
      url: "/article/category/content",
      method: "get",
      params,
    });
  },
  // topicInfo页面文章列表获取
  topic_info_list(params) {
    return request({
      url: "/article/category2",
      method: "GET",
      params,
    });
  },
  list_by_user_id(params) {
    return request({
      url: "/article/user-id",
      method: "GET",
      params,
    });
  },
  list_by_category1(params) {
    return request({
      url: "/article/category1",
      method: "GET",
      params,
    });
  },
};

export const category = {
  list(params) {
    return request({
      url: base.category.getlist,
      method: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.category.create,
      method: "post",
      data,
    });
  },
  detail(data) {
    return request({
      url: base.category.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.category.update + data.id,
      method: "put",
      data,
    });
  },
  delete(data) {
    return request({
      url: base.category.delete + data.id,
      method: "delete",
      data,
    });
  },
};

export const comment = {
  list(params) {
    return request({
      url: base.comment.getlist,
      method: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.comment.create,
      method: "post",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  detail(data) {
    return request({
      url: base.comment.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.comment.update + data.id,
      method: "put",
      data,
    });
  },
  delete(data) {
    return request({
      url: base.comment.delete + data.id,
      method: "delete",
      data,
    });
  },
  target(data) {
    return request({
      url: base.comment.target,
      method: "get",
      data,
    });
  },
};

export const reply = {
  list(params) {
    return request({
      url: base.reply.getlist,
      method: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.reply.create,
      method: "post",
      data,
    });
  },
  detail(data) {
    return request({
      url: base.reply.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.reply.update + data.id,
      method: "put",
      data,
    });
  },
  delete(data) {
    return request({
      url: base.reply.delete + data.id,
      method: "delete",
      data,
    });
  },
};
export const user = {
  create(data) {
    return request({
      url: base.user.create,
      method: "post",
      data,
    });
  },
  detail(data) {
    return request({
      url: base.user.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.user.update + data.id,
      method: "put",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  login(params) {
    return request({
      url: base.user.login,
      method: "get",
      params,
    });
  },
  register(data) {
    return request({
      url: base.user.register,
      method: "post",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  update_tag(params) {
    return request({
      url: "/user/update/tag",
      method: "get",
      params,
    });
  },
  delete(data) {
    return request({
      url: base.user.delete + data.id,
      method: "delete",
      data,
    });
  },
  update_signature(params) {
    return request({
      url: "/user/update/signature",
      method: "get",
      params,
    });
  },
  // user_avatar(params) {
  //   return request({
  //     url: "/user/avatar",
  //     method: "get",
  //     params,
  //   });
  // },
};

export const chat = {
  recent_person(params) {
    return request({
      url: base.chat.recent_person,
      method: "get",
      params,
    });
  },

  msg_list(params) {
    return request({
      url: base.chat.msg_list,
      method: "get",
      params,
    });
  },

  add_chat(data) {
    return request({
      url: "/chat/add",
      method: "post",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
};
export const chart = {
  real_time(params = {}) {
    return request({
      url: "/chart/real-time-pulse",
      method: "get",
      params,
    });
  },
};

export const hotdata = {
  weibo(params) {
    return request({
      url: base.hotdata_top.weibo + params.num,
      method: "get",
      params,
    });
  },
  aqiyi(params) {
    return request({
      url: base.hotdata_top.aqiyi + params.num,
      method: "get",
      params,
    });
  },
  douyin(params = {}) {
    return request({
      url: base.hotdata_top.douyin + params.num,
      method: "get",
      params,
    });
  },
  bilibili(params = {}) {
    return request({
      url: base.hotdata_top.bilibili + params.num,
      method: "get",
      params,
    });
  },
  baidu(params = {}) {
    return request({
      url: base.hotdata_top.baidu + params.num,
      method: "get",
      params,
    });
  },
  // 获取某天爱奇艺的所有热搜数据
  all_aqiyi(params = {}) {
    return request({
      url: "/aqiyi/all-data",
      method: "get",
      params,
    });
  },
};

export const home = {
  carousel_img(params = {}) {
    return request({
      url: "/home/carousel-img",
      method: "get",
      params,
    });
  },
  get_topic_list(params = {}) {
    return request({
      url: "/home/hot-topic",
      method: "get",
      params,
    });
  },
  get_article_list(params = {}) {
    return request({
      url: "/home/hot-article", ///home/hot-article
      method: "get",
      params,
    });
  },
};

export const baike = {
  baike_data(params) {
    return request({
      url: "/baike/data",
      method: "get",
      params,
    });
  },
};
export const topic = {
  baidu_info(params) {
    return request({
      url: "/topic/baidu",
      method: "get",
      params,
    });
  },
};
