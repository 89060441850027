<template>
  <div
    id="app"
    element-loading-text="拼命加载中,请稍等"
    v-loading="page_loading"
  >
    <router-view></router-view>
    <el-backtop target="#app"></el-backtop>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      page_loading: (state) => state.utils.page_loading,
      isLoginStatus: (state) => state.user.isLoginStatus,
      infoChange: (state) => state.user.infoChange,
    }),
  },
  watch: {
    $route(val, OldVal) {
      // console.log(val, OldVal);
      if (this.isLoginStatus) {
        return;
      }
      if (this.infoChange == 0 && sessionStorage.getItem("userInfo")) {
        let user_info = JSON.parse(sessionStorage.getItem("userInfo"));
        this.$store.commit("user/set_userInfo", user_info);
        this.$store.commit("user/set_login_status", true);
        console.log("采用本地用户数据");
      } else if (sessionStorage.getItem("userInfo")) {
        let user_info = JSON.parse(sessionStorage.getItem("userInfo"));
        this.$store.dispatch("user/userLogin", {
          username: user_info.username,
          password: user_info.password,
        });
        console.log("采用请求数据方式获取用户");
      }
      if (val.path === "/login") {
        return;
      }
    },
  },
};
</script>

<style></style>
