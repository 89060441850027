const base = {
  article: {
    getlist: "/article/list",
    create: "/article",
    getDetail: "/article/",
    update: "/article/",
    delete: "/article/",
  },
  category: {
    getlist: "/category",
    create: "/category",
    getDetail: "/category/",
    update: "/category/",
    delete: "/category/",
  },
  comment: {
    getlist: "/comment",
    create: "/comment",
    getDetail: "/comment/",
    update: "/comment/",
    delete: "/comment/",
    target: "/comment/target/list",
  },
  reply: {
    getlist: "/reply",
    create: "/reply",
    getDetail: "/reply/",
    update: "/reply/",
    delete: "/reply/",
  },
  user: {
    getlist: "/user",
    create: "/user",
    getDetail: "/user/",
    update: "/user/update/",
    delete: "/user/",
    login: "/user/login",
    register: "/user/register",
  },
  chat: {
    recent_person: "/chat/recent-person",
    msg_list: "/chat/msg-list",
  },
  hotdata_top: {
    weibo: "topic/board/weibo/hot-data/",
    douyin: "topic/board/douyin/hot-data/",
    bilibili: "topic/board/bilibili/hot-data/",
    baidu: "topic/board/baidu/hot-data/",
    aqiyi: "topic/board/aqiyi/hot-data/",
  },
};

export default base;
