<template>
  <footer id="footer">
    <div class="container">
      <div class="msg">热搜聚力论坛 @ 2023 计算机设计大赛参赛作品</div>
      <div class="credit"></div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#footer {
  background: #7f8494;
  padding: 30px 0 30px 0;
  color: #fff;
  font: size 14px;
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .msg {
      text-align: center;
      padding: top 30px;
    }
    .credit {
      padding-top: 10px;
      text-align: center;
      font-size: 13px;
      color: #fff;
    }
  }
}
</style>
