<template>
  <el-menu
    default-active="/home"
    class="el-menu"
    mode="horizontal"
    @select="handleSelect"
    router
  >
    <el-menu-item
      ><a href="/"
        ><img src="http://img.gulihanjiang.top/upload/iEnaI3EwWQ.png" alt=""
      /></a>
    </el-menu-item>
    <el-menu-item index="/">首页</el-menu-item>
    <el-menu-item index="/topic/board">热搜广场</el-menu-item>
    <el-menu-item index="/article/board/weibo">帖子广场</el-menu-item>
    <el-submenu index="/user" class="user-center" v-if="isLoginStatus">
      <template slot="title"
        ><el-avatar size="medium" :src="userInfo.avatar"></el-avatar
      ></template>
      <el-menu-item :index="'/user/chat/' + chat_params">我的消息</el-menu-item>
      <el-menu-item index="/user/center">用户中心</el-menu-item>
      <el-menu-item index="/login">退出登录</el-menu-item>
    </el-submenu>
    <el-menu-item
      class="login-btn"
      v-on:click="no_need_login"
      v-show="!isLoginStatus"
      >免登录体验</el-menu-item
    >
    <el-menu-item index="/login" class="login-btn" v-show="!isLoginStatus"
      >登录</el-menu-item
    >
  </el-menu>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NavBar",
  data() {
    return {
      general_id: 0,
      user: {
        username: "李二水",
        password: "123456a_",
        email: "",
      },
    };
  },
  watch: {
    $route(to, from) {
      // console.log("路由来自", to, "前往", from);
      this.$store.dispatch("utils/change_loading");
    },
  },
  methods: {
    handleSelect(index) {
      console.log("更改后的路径", index);
    },
    no_need_login() {
      this.$store.dispatch("user/userLogin", this.user).then((res) => {
        if (res.data.code == 201) {
          this.$message.warning("用户名或密码错误");
          return false;
        } else if (res.data.code == 202) {
          this.$message.warning("密码错误");
          return false;
        } else if (res.data.code == 200) {
          this.$message.success("登陆成功");
          this.isLoading = true;

          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        }
      });
    },
  },
  computed: {
    ...mapState({
      isLoginStatus: (state) => state.user.isLoginStatus,
      userInfo: (state) => state.user.userInfo,
    }),
    chat_params() {
      if (this.isLoginStatus) {
        // console.log("用户信息", this.userInfo);
        return this.userInfo.id;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    console.log("当前的用户信息", this.userInfo);
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  // height: 90px;
  padding-left: 100px;
  .el-menu-item {
    font-size: 15px;
    img {
      height: 80%;
      width: auto;
    }
  }
}
.user-center {
  float: right !important;
}
.login-btn {
  float: right !important;
  margin-right: 20px;
}
</style>
