import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import utils from "./modules/utils";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    utils,
  },
});

export default store;
