import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout/Layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home/Home.vue"),
      },
      {
        path: "user",
        name: "User",
        component: () => import("@/views/user/Index.vue"),
        children: [
          {
            path: "center",
            name: "UserCenter",
            redirect: "/user/center/info",
            component: () => import("@/views/user/pages/UserCenter.vue"),
            children: [
              {
                path: "info",
                name: "UserInfo",
                component: () => import("@/views/user/rightPart/UserInfo.vue"),
              },
              {
                path: "setting",
                name: "Setting",
                component: () =>
                  import("@/views/user/rightPart/AccountSetting.vue"),
              },
              {
                path: "create",
                name: "CreateArticle",
                component: () =>
                  import("@/views/user/rightPart/CreateArticle.vue"),
              },
              {
                path: "success",
                name: "CreateSuccess",
                component: () =>
                  import("@/views/user/rightPart/CreateSuccess.vue"),
              },
            ],
          },
          {
            path: "chat/:id",
            name: "UserChat",
            component: () => import("@/views/user/pages/UserChat.vue"),
          },
          {
            path: "info",
            component: () => import("@/views/user/pages/UserContent.vue"),
          },
        ],
      },
      {
        path: "article",
        name: "Article",
        component: () => import("@/views/article/Index.vue"),
        children: [
          {
            path: "detail",
            name: "ArticleDetail",
            component: () => import("@/views/article/pages/ArticleDetail.vue"),
          },
          {
            path: "board/:category",
            name: "ArticleBoard",
            component: () => import("@/views/article/pages/ArticleBoard.vue"),
          },
        ],
      },
      {
        path: "topic",
        name: "Topic",
        component: () => import("@/views/topic/Index.vue"),
        children: [
          {
            path: "board",
            name: "TopicBoard",
            component: () => import("@/views/topic/pages/TopicBoard.vue"),
          },
          {
            path: "category/:category1/:category2",
            name: "TopicCategory",
            component: () => import("@/views/topic/pages/TopicCategory.vue"),
          },
          {
            path: "info",
            name: "TopicInfo",
            component: () => import("@/views/topic/pages/TopicInfo.vue"),
          },
          {
            path: "baike",
            name: "TopicBaike",
            component: () => import("@/views/topic/pages/TopicBaike.vue"),
          },
        ],
      },
      {
        path: "data",
        name: "data",
        component: () => import("@/views/dataPage/index.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/test/Test.vue"),
  },
  {
    path: "/chat/:id",
    name: "chat",
    component: () => import("@/views/chat/Index.vue"),
  },
  {
    path: "/another-chat",
    name: "anotherChat",
    component: () => import("@/views/chat/AnotherChat.vue"),
  },
  {
    path: "/realTime",
    name: "realTime",
    component: () => import("@/components/charts/RealTimePulse.vue"),
  },
  {
    path: "/dynamic-sort",
    name: "DynamicSort",
    component: () => import("@/components/charts/DynamicSort.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
