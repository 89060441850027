const state = () => ({
  page_loading: false,
});

const getters = {};
const actions = {
  change_loading({ commit }, data = {}) {
    commit("set_loading", true);
    setTimeout(() => {
      commit("set_loading", false);
    }, 2000);
  },
};

const mutations = {
  set_loading(state, data) {
    state.page_loading = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
